<template>
  <button type="button" class="btn bg-green-800 btn-raised active" @click="clicked">{{title}}</button>
</template>

<script>

export default {
  name: 'SaveButton',
  props: {
    title: {
      type: String,
      required: true,
      default: 'Save',
    },
  },
  created () {
    // this.$props.title = "Clicked!!!";
  },
  methods:{
    clicked() {
      const self = this;

      self.emit('save_clicked');
    },
  }
}
</script>

<style scoped>

</style>
